import { GlobalObservableKey, useGlobalObservable } from "open-observable";
import React, { ReactNode, useCallback } from "react";

type Modal = {
    content: ReactNode;
    id: string;
};

export const modalsArray2 = new GlobalObservableKey<any>('modals2', []);

interface UseModalReturnType {
    addModal: (content: ReactNode, id: string) => void;
    removeModal: (id: string) => void;
}

export default function useModal2(): UseModalReturnType {
    const modalValue = useGlobalObservable(modalsArray2);

    React.useEffect(() => {
        return () => { };
    }, []);

    const addModal = useCallback(
          (content: ReactNode, id: string) => {
                if (!modalValue) return;
                console.log(modalValue)
            modalValue.next((old: Modal[]) => [{ content, id }]);
        },
        [modalValue]
    );

    const removeModal = useCallback(
        (id: string) => {
            if (!modalValue) return;
            modalValue.next((old: Modal[]) => old.filter(modal => modal.id !== id));
        },
        [modalValue]
    );

    return { addModal, removeModal };
}
