import { FormConfig, KnownFormError } from 'm-open-form';
import React, { Fragment } from 'react';

function fixName(name: string): string {
    if (name.length === 0) return '';

    return name[0].toLowerCase() + name.substring(1);
}

function errorTranslate(result: any): KnownFormError {
      
    const errors: KnownFormError<any> = {} as KnownFormError<any>;
    
    Object.entries(result).forEach(([key, value]) => {
        
        if((value as string).length == undefined) {

            Object.entries(value as object).forEach(([k, v]) => {
               errors[key + '_' + k] = v as string[];
            });
        } else {
            errors[key] = value as string
        }
    });

    return errors;
   
}

export function Config() {


    return (
        <Fragment>
            <FormConfig errorTranslate={errorTranslate} />
        </Fragment>
    );
}