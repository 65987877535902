import { formatRouteBuild } from 'other/format-route-build';
import { pages } from 'pages';
import { FC } from 'react';
import { RenderLayout } from './layout/base-layout/components';
import NotificationsRenderer from 'components/alerts/notifications';
import Theme from 'components/ThemeContext';
import Modals from 'components/Modals';
import LoadingFallback from 'layout/base-layout/components/loading-fallback';

type Routes = keyof typeof pages;

export const route = formatRouteBuild<Routes>();

export const App: FC = () => {
      return (
            <>
                  <NotificationsRenderer />
                        <Theme>
                              <RenderLayout pages={pages} initial={route('/login')} />
                              <Modals />
                              <LoadingFallback />
                        </Theme>
            </>
      );
};
