import LoadingPage from 'components/LoadingPage';
import { GlobalObservableKey, useGlobalObservable, useSubscriber } from 'open-observable';

export const is_loading = new GlobalObservableKey<boolean>('isLoading', false);

export default function LoadingFallback() {

      const isLoading = useSubscriber(useGlobalObservable(is_loading));

      return (
            isLoading ? (
                  <div
                        className='position-absolute h-100 w-100 align-items-center justify-content-center d-flex rounded-5'
                        style={{ background: 'rgb(128 128 128 / 76%)', top: 0, left: 0, zIndex: 1000 }}
                  >
                        <LoadingPage />
                  </div>
            ) : <></>
      );
}
