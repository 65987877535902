import './index.css';

type Props = {
      text?:boolean
}

export default function LoadingPage({text = false}: Props ) {
      return (
            <div className='min-vh-100 w-100 d-flex flex-column align-items-center justify-content-center '>
                  <div className='holder d-flex align-items-center'>
                        <div className='mask-holder'>
                              <div className='mask'></div>
                        </div>
                        <div className='symbol'></div>
                       {text && <div className="ms-3">
                              <img src={require('../../assets/svg/logo_qs_letras.svg').default} height={150} alt="" />
                        </div>}
                  </div>
            </div>
      );
}

